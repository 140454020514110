//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect } from 'react';
import { Navbar, Nav, Carousel, NavDropdown, Container, Row, Col } from 'react-bootstrap';
function Navegacion() {
    return (

        <Navbar style={{position:"absolute",zIndex:"90"}}  data-bs-theme="dark" className='sbt transBck w-100'>
            <Row className="w-100  text-white m-0">
                <Navbar.Brand className='' href="#Home">SMIC ingeniería y construccion</Navbar.Brand>
                <Col>
                    <Nav.Link href="#Home"  className='p-2 nav-option' >Inicio</Nav.Link>
                </Col>
                <Col>
                    <Nav.Link href="#Servicios" className='p-2 nav-option'>Servicios</Nav.Link>
                </Col>
                <Col>
                    <Nav.Link href="#Contacto" className='p-2 nav-option'>Contacto</Nav.Link>
                </Col>
            </Row>
        </Navbar>
    );
}

export default Navegacion;