import { Container, Row, Col, Carousel, Button } from "react-bootstrap";
import ps1 from "../resources/images/piscinas/ps1.jpg";
import ps2 from "../resources/images/piscinas/ps2.jpg";
import ps3 from "../resources/images/piscinas/ps3.jpg";
import ps4 from "../resources/images/piscinas/ps4.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAngleRight, faAnglesRight, faUmbrellaBeach } from '@fortawesome/free-solid-svg-icons'

function Albercas() {
    return (
        <Row className="p-0 m-0 w-100 h-100 " >
            <Col className="bg-dark p-0 m-0" md={6} sm={12}>
                <p className="p-3 sbt text-white bg-pool">HACEMOS TU IDEA REALIDAD</p>
                <div className="p-5">
                    <h1 className="text-white lightTitle">Construimos tu piscina!</h1>
                    <br />
                    <p className="text-white sbt p-3">Estamos emocionados de asociarnos contigo para crear la alberca de tus sueños. En AquaBelleza, cada alberca que construimos es una expresión de arte y excelencia. ¡Déjanos convertir tu visión en una realidad acuática que te cautivará y deleitará durante años venideros!

                    </p>
                    <br />
                    <Row>
                        <Col>
                            <Button variant="outline-light p-3" href="#Contacto" >
                                Contacto
                                <FontAwesomeIcon style={{ marginLeft: "5px" }} shake icon={faAnglesRight} />
                            </Button>
                        </Col>
                        <Col>

                            <Container className="pl-5 ml-5">
                                <FontAwesomeIcon bounce className="text-white" icon={faUmbrellaBeach} size="6x" />
                            </Container>
                        </Col>
                    </Row>
                </div>


            </Col>
            <Col className="m-0 p-0 h-100" md={6} sm={12}>
                <Carousel className="h-100" controls={false}>
                    <Carousel.Item className="h-100" interval={800}>
                        <div className=' h-100'>
                            <img className='d-block w-100 vh-100 banner-image zoom-in-out-box' src={ps1} />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={800}>
                        <div className=' '>
                            <img className='d-block w-100 vh-100 banner-image zoom-in-out-box' src={ps2} />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={800}>
                        <div className=' '>
                            <img className='d-block w-100 vh-100 banner-image zoom-in-out-box' src={ps3} />
                        </div>
                    </Carousel.Item>
                    <Carousel.Item interval={800}>
                        <div className=' '>
                            <img className='d-block w-100 vh-100 banner-image zoom-in-out-box' src={ps4} />
                        </div>
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    );
}

export default Albercas;