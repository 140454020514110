import { Col, Container, Row, Carousel, Button, Form, Toast, ToastContainer } from "react-bootstrap";
import p11 from "../resources/images/portafolio/p11.jpg";
import p12 from "../resources/images/portafolio/p12.jpg";
import p13 from "../resources/images/portafolio/p13.jpg";
import p14 from "../resources/images/portafolio/p14.jpg";
import p15 from "../resources/images/portafolio/p15.jpg";
import p16 from "../resources/images/portafolio/p16.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faGlobe, faPhone } from "@fortawesome/free-solid-svg-icons";
import { ref, set } from "firebase/database";
import { db } from "../fbConfig";
import { v4 as uuidv4 } from 'uuid';
import { createRef, useState } from "react";
import timestamp from "time-stamp";

const formRef = createRef();
function About() {
    const [show, setShow] = useState(false);
    const sendComments = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const form = e.target
        const request = {
            nombre: form[0].value,
            mail: form[1].value,
            comentarios: form[2].value,
            date: timestamp("YYYY/MM/DD")
        }

        // send to fb
        const uid = uuidv4();
        set(ref(db, "Contacto/" + uid), { ...request, Uid: uid }).then((e) => {
            console.log(formRef);
            console.log(request);
            formRef.current.reset();
            setShow(true);
        });
    }
    return (<>
        <Container fluid={true} className="bg-dark  w-100 no-gutters" id="Contacto">
            <ToastContainer position="top-end" style={{ position: "fixed" }}>
                <Toast onClose={() => setShow(false)} show={show} delay={3000} autohide bg="dark" >
                    <Toast.Header>
                        <img
                            src="holder.js/20x20?text=%20"
                            className="rounded me-2"
                            alt=""
                        />
                        <strong className="me-auto">SMIC</strong>
                        <small>Ahora mismo</small>
                    </Toast.Header>
                    <Toast.Body className="text-white">Comentarios enviados !!...</Toast.Body>
                </Toast>
            </ToastContainer>

            <Row>
                <Col lg={8} className="homeBanner vh-100 p-0 m-0">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3794.651968651855!2d-93.37832700000001!3d17.994922000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDU5JzQxLjciTiA5M8KwMjInNDIuMCJX!5e0!3m2!1ses-419!2smx!4v1710524084886!5m2!1ses-419!2smx" style={{ width: "100%", height: "100%" }} referrerpolicy="no-referrer-when-downgrade"></iframe>
                </Col>
                <Col className="vh-100">
                    <Row className="text-white bg-pool h-25 p-0 ">
                        <h2 className="lightTitle p-3">INFORMACION DE CONTACTO</h2>
                        <div  >
                            <div  >
                                <FontAwesomeIcon icon={faPhone} />
                                <p className="minSbt" style={{ display: "inline-block", marginInline: "10px" }}>
                                    937 11 63 315
                                </p>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faEnvelope} />
                                <p className="minSbt" style={{ display: "inline-block", marginInline: "10px" }}>
                                    atencionalcliente@smic.com.mx
                                </p>
                            </div>
                            <div>
                                <FontAwesomeIcon icon={faGlobe} />
                                <p className="minSbt" style={{ display: "inline-block", marginInline: "10px" }}>
                                    www.smic.com.mx
                                </p>
                            </div>
                        </div>
                    </Row>
                    <Row className="sbt h-50">
                        <p style={{ textAlign: "center", padding: "10px" }} className="bg-grass text-white">Envianos un mensaje!</p>
                        <Form ref={formRef} name="AboutForm" onSubmit={sendComments} >
                            <Form.Group className="mb-1" controlId="formBasicEmail">
                                <Form.Label>Nombre o empresa</Form.Label>
                                <Form.Control type="text" placeholder="Nombre" />
                            </Form.Group>
                            <Form.Group className="mb-1" controlId="formBasicEmail">
                                <Form.Label>Correo electronico</Form.Label>
                                <Form.Control type="email" placeholder="Correo electronico" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Escribe tu mensaje</Form.Label>
                                <Form.Control as="textarea" rows={3} />
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Enviar!
                            </Button>
                        </Form>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>);
}

export default About;