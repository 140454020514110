import whatsapp from "../resources/images/whatsapp.png";
function SocialMedia() {
    return (<a href="https://wa.link/7i8qtn">
        <img src={whatsapp} style={{
            position: "fixed",
            bottom: "5%",
            right: "10%",
            width: "4rem",
            height: "4rem",
            zIndex: "50"
        }} />
    </a>);
}

export default SocialMedia;