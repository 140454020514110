import { Col, Container, Row } from "react-bootstrap";
import p1 from "../resources/images/portafolio/p1.jpg";
import p2 from "../resources/images/portafolio/p2.jpg";
import p11 from "../resources/images/portafolio/p11.jpg";
import p16 from "../resources/images/portafolio/p16.jpg";
import { Parallax, ParallaxLayer } from '@react-spring/parallax'
import { Navbar, Nav, Carousel, NavDropdown, Card, Button } from 'react-bootstrap';
import Banner from "../Components/banner";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartSimple, faAnglesUp, faPenRuler, faPersonDigging } from '@fortawesome/free-solid-svg-icons'
import BannerCard from "../Components/bannerCard";

function Home() {

    return (<  >
        <Row className="bg-dark p-0 w-100 m-0  text-white" >
            <Col className="no-gutters" md={6} lg={6} sm={12}>
                <h1 className="p-3 text-center text-white lightTitle bg-grass">Manofactura, ingeniería y construcción
                </h1>
                <div className="sbt  p-5" style={{ verticalAlign: "middle" }}>
                    <p>SMIC Construcciones, donde cada proyecto es más que una construcción; es la materialización de sueños, la creación de espacios que inspiran y la manifestación de la excelencia en cada detalle. Con más de 10 años de experiencia, SMIC Construcciones se destaca como una firma comprometida con la innovación, la calidad y la satisfacción del cliente</p>
                </div>
            </Col>
            <Col className="homeBanner"md={6} lg={6} sm={12} id="Home">
                <p className="sbt p-4" style={{ textAlign: "end" }}>Portafolio que nos respalda</p>
                <Carousel style={{}} controls={false}>
                    <Carousel.Item interval={2000} className="miniBanner">
                        <img className=' w-100 h-100 banner-image zoom-in-out-box' src={p2} />
                    </Carousel.Item>
                    <Carousel.Item interval={2000} className="miniBanner">
                        <img className=' w-100 h-100 banner-image zoom-in-out-box' src={p11} />
                    </Carousel.Item>
                    <Carousel.Item interval={2000} className="miniBanner">
                        <img className=' w-100 h-100 banner-image zoom-in-out-box' src={p16} />
                    </Carousel.Item>
                    <Carousel.Item interval={2000} className="miniBanner">
                        <img className=' w-100 h-100 banner-image zoom-in-out-box' src={p1} />
                    </Carousel.Item>
                </Carousel>
            </Col>
        </Row>
    </>);
}

export default Home;