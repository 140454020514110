import './App.css';
//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Carousel, NavDropdown, Container } from 'react-bootstrap';

import { Parallax, ParallaxLayer } from '@react-spring/parallax'

// Media
import Navegacion from './Components/navegacion';
import Banner from './Components/banner';
import BannerCard from './Components/bannerCard';
import Home from './Pages/home';
import About from './Pages/about';
import Background1 from './Components/background1';
import MenuServicios from './Pages/menuServicios';
import Albercas from './Pages/albercas';
import MenuGaleria from './Components/menuGaleria';
import SocialMedia from './Components/socialMedia';
import Footer from './Components/footer';



function App() {

  return (
    <div className="App">
      <Navegacion />
      <Banner />
      <SocialMedia />
      <BannerCard />
      <Home />
      <Albercas />
      <MenuServicios />
      <MenuGaleria />
      <About />
    </div>
  );
}

export default App;
