import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useState, createRef, useEffect } from 'react';
import { getStorage, ref as refStorage, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { ref, set, getDatabase, child, get, remove } from "firebase/database";
import { db } from "../fbConfig";
const storage = getStorage();
function Cards() {
    const [elements, setElements] = useState([<></>])
    useEffect(() => {
        loadCards();
    }, [])
    const loadCards = () => {
        const dbRef = ref(getDatabase());
        get(child(dbRef, "/Servicios")).then((snapshot) => {
            if (snapshot.exists()) {
                const Data = Object.values(snapshot.val())
                console.log(Data);
                setElements(Data)
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    const getImg = (uid) => {
        getDownloadURL(refStorage(storage, `servicios/${uid}.jpg`))
            .then((url) => {
                // `url` is the download URL '

                const img = document.getElementById(uid);
                img.setAttribute('src', url);
                return url
            })
            .catch((error) => {
                console.log(error);
            });
    }
    return (
        <>
            <Row className="m-0 pt-5   w-100" >
                {elements.map((snapChild, key) => {
                    return (
                        <Col md={3} className="" style={{ alignItems: "center" }}>
                            <Card key={key} style={{ height:"100%" }} className="bg-dark">
                            <Card.Title className="bg-grass p-2 text-center text-white">{snapChild.Servicio}</Card.Title>
                            <Card.Img
                                style={{ height: "45%" ,borderRadius:0}}
                                id={snapChild.Uid}
                                alt="example"
                                src={getImg(snapChild.Uid)}
                                variant="top" />
                            <Card.Body className="ngi-dark-color text-white p-0" style={{ borderRadius: 0 }}>
                                
                                <Card.Text className="m-2 p-3">
                                    <h3 className="sbt">{snapChild.Descripcion}</h3>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                            </Col>
            )
                    })}
        </Row >
        </>
    );
}

export default Cards;