import { faAnglesUp, faPenRuler, faPersonDigging, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import work from "../resources/images/home/work.jpg"
import { useState } from "react";
import Cards from "../Components/cards";

function MenuServicios() {
    const [open, setOpen] = useState(false);
    const styles = {
        position: "relative",
        width: "100%",
        height: "23vh",
    }

    return (
        <>
            <h1 className="bg-grass text-white text-center m-0 p-0" >Principales servicios</h1>
            <Row className="bg-pool w-100 m-0 p-0 " id="Servicios" >
                <Col sm={12} md={4} lg={4} >
                    <Card className="m-3">
                        <Card.Body>
                            <Card.Title className="bg-dark text-white">
                                Desarrollo
                            </Card.Title>
                            <FontAwesomeIcon icon={faChartSimple} size="6x" fade className="m-4" />
                            <Card.Subtitle className="mb-2 text-muted">Diseño y Planificación</Card.Subtitle>

                            <Card.Text>
                                Diseño arquitectónico personalizado.
                                <br />
                                Planificación detallada para optimizar recursos y plazos..
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4} lg={4}>
                    <Card className="m-3">
                        <Card.Body>
                            <Card.Title>
                                Construcción
                            </Card.Title>
                            <FontAwesomeIcon icon={faPersonDigging} size="6x" fade className="m-4" />
                            <Card.Subtitle className="mb-2 text-muted">Ejecucion y supervision</Card.Subtitle>

                            <Card.Text>
                                Ejecución de proyectos con estándares de calidad.
                                <br />
                                Coordinación eficiente y supervisión constante.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4} lg={4}>
                    <Card className="m-3">
                        <Card.Body>
                            <Card.Title>
                                Renovación y Rehabilitación
                            </Card.Title>
                            <FontAwesomeIcon icon={faPenRuler} size="5x" fade className="m-4" />
                            <Card.Subtitle className="mb-2 text-muted">Diseño y Planificación</Card.Subtitle>

                            <Card.Text>
                                Transformación de espacios existentes con enfoque en la eficiencia energética.
                                <br />
                                Modernización y adaptación a estándares actuales.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="w-100 m-0">
                <Col className="m-0 p-0 w-100" >
                    <div style={styles} className="bg-dark  ">
                        <div style={{ alignContent: "start" }} className="bg-grass">
                            <Button variant="outline-dark" blo onClick={() => setOpen(true)} className="bg-dark" style={{ borderRadius: 0, alignContent: "start", marginLeft: "1%", marginBottom: "5px", color: "white", padding: "10px", width: "99%" }}>
                                Click aqui!
                                <FontAwesomeIcon icon={faAnglesUp} bounce className="text-white" size="lg" style={{ marginLeft: "5px" }} />
                            </Button>
                        </div>
                        <div style={{ padding: "10px", color: "white" }}>
                            <h1 className="lightTitle">Catalogo de servicios!</h1>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="w-100 m-0 p-0">
                <Col className="text-white ngi-dark-color" sm={12} md={6} lg={6}>
                    <h1 className="text-center ">Servicios</h1>
                    <div className="bg-grass p-1"></div>
                    <div className="p-5 sbt">
                        <p>
                            ° Supervicion de obra
                        </p>
                        <p>
                            ° Construccion de albercas
                        </p>
                        <p>
                            ° Obra Civil
                        </p>
                        <p>
                            ° Edificios
                        </p>
                        <p>
                            ° Construccion industrial
                        </p>
                        <p>
                            ° Casa habitacion
                        </p>
                        <p>
                            ° Proyectos ejecutivos
                        </p>
                        <p>
                            ° Mantenimiento gral.
                        </p>
                        <p>
                            ° Calculo estructural
                        </p>
                    </div>
                </Col>
                <Col className="bg-dark text-white" sm={12} md={6} lg={6}>
                    <h1 className="text-center ">Venta</h1>
                    <div className="bg-grass p-1"></div>
                    <div className="p-5 sbt">
                        <p>
                            ° Equipos
                        </p>
                        <p>
                            ° Filtros
                        </p>
                        <p>
                            ° Motores
                        </p>
                        <p>
                            ° Veneciano
                        </p>
                        <p>
                            ° Cascadas
                        </p>
                        <p>
                            ° Luces
                        </p>
                        <p>
                            ° Mat. filtrante
                        </p>
                    </div>
                </Col>
            </Row>
            <Modal className="bg-dark" show={open} fullscreen={true} onHide={() => setOpen(false)}>
                <Modal.Header className="bg-primary text-white" closeButton >
                    <Modal.Title >Catalogo de servicios</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-dark text-white">
                    <Cards/>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default MenuServicios;