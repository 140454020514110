import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBOKdlNj94QVmoeI2Zo8ApjmEXh2_fNqXU",
  authDomain: "smicpanel.firebaseapp.com",
  databaseURL: "https://smicpanel-default-rtdb.firebaseio.com",
  projectId: "smicpanel",
  storageBucket: "smicpanel.appspot.com",
  messagingSenderId: "14022329819",
  appId: "1:14022329819:web:9eac465484dfdaa06713fe",
  storageBucket: "smicpanel.appspot.com",
  measurementId: "G-MKVR93WLQ5"
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getDatabase(app);