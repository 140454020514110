//Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Carousel, NavDropdown, Container } from 'react-bootstrap';
// Media
import b1 from "../resources/images/banner/b1.jpg"
import b2 from "../resources/images/banner/b2.jpg"
import b3 from "../resources/images/banner/b3.jpg"
import b4 from "../resources/images/banner/b4.jpg"
import casa from "../resources/images/portafolio/p2.jpg"
import BannerCard from './bannerCard';
function Banner() {
    return (
        <>
            <Carousel controls={false}>
                <Carousel.Item interval={2000}>
                    <div className=' '>
                        <img className=' d-block w-100 vh-100 banner-image zoom-in-out-box' src={b3} />
                    </div>
                    <Carousel.Caption>
                        <h3>Seguridad y vanguardia</h3>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <div className=' '>
                        <img className='d-block w-100 vh-100 banner-image zoom-in-out-box' src={casa} />
                    </div>
                    <Carousel.Caption>
                        <h3>Ingenieria y desarrollo</h3>
                    </Carousel.Caption>

                </Carousel.Item>
                <Carousel.Item interval={2000}>
                    <div className=' '>
                        <img className='d-block w-100 vh-100 banner-image zoom-in-out-box' src={b1} />
                    </div>
                    <Carousel.Caption>
                        <h3>Ingenieria y desarrollo</h3>
                    </Carousel.Caption>

                </Carousel.Item>
            </Carousel>
            <div style={{ position: "relative", top: "-100%", width: "100%", height: "100%", backgroundColor: " rgba(0, 0, 0, 0.350)" }}>.</div>
        </>
    );
}

export default Banner;