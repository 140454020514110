function BannerCard() {
    const styles = {
        position: "absolute",
        top:"55%",
        width: "35%",
        zindex: "50"
    }
    return (<>
        <div style={styles}  className="bg-dark radiosDown" >
            <p className="bg-grass sbt text-white radiosUp" style={{ paddingLeft: "5px", padding: "10px" }}>Somos tu mejor opcion</p>
            <div className="p-3 text-white" style={{position:'relative'}}>
                <h1>Crea tu proyecto con nosotros!</h1>
                <span>Contactanos</span>
            </div>
        </div>
    </>);
}

export default BannerCard;