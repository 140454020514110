import { Container, Row, Col } from "react-bootstrap";
import p1 from "../resources/images/portafolio/p1.jpg";
import p2 from "../resources/images/portafolio/p2.jpg";
import p3 from "../resources/images/portafolio/p3.jpg";
import p4 from "../resources/images/portafolio/p4.jpg";
import p5 from "../resources/images/portafolio/p5.jpg";
import p6 from "../resources/images/portafolio/p6.jpg";
import p7 from "../resources/images/portafolio/p7.jpg";
import p8 from "../resources/images/portafolio/p8.jpg";
import p9 from "../resources/images/portafolio/p9.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faPenRuler, faPersonDigging, faChartSimple, faAnglesUp, faAnglesRight, faMaximize, faComputerMouse } from "@fortawesome/free-solid-svg-icons";

function MenuGaleria() {
    return (
        <>
            <Row className="w-100 p-0 m-0 bg-pool w-100">
                <Col className="p-0">
                    <div style={{ position: "relative", top: "-50" }} className="w-25">
                        <h1 className="bg-grass  text-center text-white">
                            Galeria
                        </h1>
                        <p className="bg-dark  text-center text-white p-1" style={{ position: "absolute", top: "80%" }}>
                            Desliza!
                            <FontAwesomeIcon icon={faAnglesRight} bounce className="text-white" size="lg" style={{ marginLeft: "5px" }} />
                        </p>
                    </div>
                    <div className="bg-dark p-3 " style={{ overflow: "auto", display: "flex", overflow: "auto", flexWrap: "nowrap", width: "100%" }}>
                        <img src={p1} className="imgCardGallery m-2" />
                        <img src={p2} className="imgCardGallery m-2" />
                        <img src={p3} className="imgCardGallery m-2" />
                        <img src={p4} className="imgCardGallery m-2" />
                        <img src={p5} className="imgCardGallery m-2" />
                        <img src={p6} className="imgCardGallery m-2" />
                        <img src={p7} className="imgCardGallery m-2" />
                        <img src={p8} className="imgCardGallery m-2" />
                    </div>
                </Col>
            </Row>


        </>
    );
}

export default MenuGaleria;